<template>

  <div class="langscontainer">
    <div v-if="langswitchsThatMustBePresent.length > 0" class="langbuttonscontainer">
      <button 
      tabindex="0" 
      v-for="lang in langswitchsThatMustBePresent" 
      :key="lang"
      :name="$t('langbuttons.arialabels.'+lang.slice(0,2))"
      :aria-label="$t('langbuttons.arialabels.'+lang.slice(0,2))"
      :class="{'active': $i18n.locale === lang}"
      :aria-pressed="$i18n.locale === lang"
      class="tolang" 
      @click="switchLangTo(lang)"
      >

      <span v-if="mode === 'countries' || mode === 'both'">{{getCountryFromLocale(lang)}}</span>
      <span v-if="mode === 'both'"> - </span>
      <span v-if="mode === 'langs' || mode === 'both'">{{ lang.slice(0,2).toUpperCase() }}</span>
      <span v-if="mode === 'locale'">{{lang}}</span>

    </button>
  </div>


  <!-- In maintenance case -->
  <div v-else class="langbuttonscontainer">

    <button 
    tabindex="0" 
    :name="$t('langbuttons.arialabels.fr')"
    :aria-label="$t('langbuttons.arialabels.fr')"
    class="tolang" 
    @click="switchLangTo('fr_BE')"
    label="FR"
    >
    FR
  </button>

  <button 
  tabindex="0" 
  :name="$t('langbuttons.arialabels.nl')"
  :aria-label="$t('langbuttons.arialabels.nl')"
  class="tolang" 
  @click="switchLangTo('nl_BE')"
  label="NL"
  >
  NL
</button>

</div>
</div>

</template>

<!-- ================================================================================== -->

<script>

	// import LangButton from '@/components/LangButton';
	// import Mixins from "../mixins/NonAutomatedData";

	export default {
		name:"localeSwitcher",
		
		props: {
			inHeader: {
				type: Boolean,
				required: false,
			default: true,
			},
      mode: {
        type: String,
        required: true,
      default: 'none'
      },
      isActive : {
        type: Boolean,
        required: true,
        default: false
      }
    },

    components: {},
    mixins: [],

    computed: {},

    data(){
      return {
       langswitchsThatMustBePresent: [],
     }
   },

   methods: {

    getCountryFromLocale(locale){
      let correspondances = {
        "nl_nl": "Nederland",
        "fr_fr": "France",
        "fr_lu": "Luxembourg",
        "fr_be": "Belgique",
        "nl_be": "België",
      };
        return correspondances[locale.toLowerCase()]; // Returns a Country or undefined
      },

      switchLangTo(locale){
        /* ! Both do the same I think… */
       this.$store.dispatch('setLocale', {locale: locale});
       this.$i18n.locale = locale;
     },
     

    }, // end of methods

    mounted(){
      this.langswitchsThatMustBePresent = this.$store.getters.getLangList;
    },

    watch: {
			// To be sure it load at startup
			'$store.state.langList': function(/* newLocale, oldLocale */){    
      this.langswitchsThatMustBePresent = this.$store.getters.getLangList;
    },
  },
}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
