<template>

	<!--<div id="loader" v-show="$store.getters.isSpinnerSpinning">
		<div class="loader-message">
			<div class="loader-visual">&nbsp;</div>
			<div>{{$t('pageTitles.wait')}}</div>
		</div>
	</div>-->

	<custom-loaders v-show="$store.getters.isSpinnerSpinning"></custom-loaders>

	<div id="fakebody">

		<!-- LINK SKIPPER -->
		<skip-link></skip-link>


		<!-- FULL PAGE FOR THE TEMPLATES -->
		<!-- A master-container, containing dedicated containers. -->
		<div id="container_of_containers">



			<div id="container_lang">
				<div id="lang_layout">

				<!-- ! This must indeed change LOCALE, as the country information IS important : 
					- it allows the correct display of the localized banner AND the General Conditions. -->
					<locale-switcher role="region" :mode="modelLangButton"></locale-switcher>

				</div>
			</div>








			<div id="container_form">
				<div id="form_layout">


					<!-- PAGE:HEADER full width top header -->
					<header id="form_container_header" :class="getLang" role="banner" tabindex="0">
						<div id="form_header_layout">
							
							<!-- Invisible Title -->
							<h1 style="position: absolute; top:-1000px; z-index: -10;">{{$t('formHeader.headerTitle')}}</h1>

							<!-- FORM:BANNER -->
							<div id="form-banner" :class="getLang"></div>

						</div>
					</header>

					<!-- PAGE:MAIN -->
					<main id="form_container_main">
						<div id="form_main_layout">

							<!-- COUNTER BANNER -->
							<participation-counter 
							v-if="counterIsVisible" 
							:counter-type="['participationsLeft', 'daysLeft'][0]" 
							:displayed-number="lead.participations_left"
							></participation-counter>

							<!-- FORM:CONTENT -->
							<div id="form-content">
								<router-view></router-view>
							</div>

						</div>
					</main>

					<header id="form_container_second_header" :class="getLang" role="banner">
						<div id="form_header_layout">
							<div id="form-banner2" :class="getLang" role="banner"></div>
						</div>
					</header>

				</div>
			</div>







			




			<!-- ALWAYS-ON-BOTTOM FOOTER -->
			<footer id="container_footer" role="contentinfo">

				<div class="footer_decoration">

					<!-- BEL CHARACTER IMAGES -->
          <!--
          <div class="footer_character"><img src="~@/assets/img/babybel_character.png"/></div>
          <div class="footer_character2"><img src="~@/assets/img/boursin_character2.png"/></div>
      -->

  </div>


  <div id="footer_layout">

  	<classic-footer v-if="!this.isPnGAction"></classic-footer>
  	<pg-footer v-if="this.isPnGAction"></pg-footer>

  </div>
</footer>





<!-- New GDPR -->
<gdpr-banner role="alertdialog" aria-label="GDPR" v-if="!this.isPnGAction && this.isGdprPresent" v-show="isGDPRVisible" :source="leadGDPR" @closeGdpr="closeGdpr"></gdpr-banner>


<!-- OneTrust, if the client use it -->
<one-trust v-if="this.isPnGAction"></one-trust>


</div><!-- End of container_of_containers -->
</div>

</template>











































<script>

	/* ======== COMPONENTS ======== */
	// import AppNav from '@/components/AppNav'; /* navigation between pages, not inside form */
	import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
	import ClassicFooter from '@/components/ClassicFooter.vue';
	import PgFooter from '@/components/PgFooter.vue';
	import ParticipationCounter from '@/components/ParticipationCounter.vue';
	import GdprBanner from '@/components/GdprBanner.vue';
	import SkipLink from '@/components/SkipLink.vue';
	import CustomLoaders from '@/components/CustomLoaders.vue';

	import OneTrust from '@/components/OneTrust.vue';

	/* ======== MIXINS ======== */
	// import pixel from "./mixins/pixel";
	import ga from "./mixins/ga";
	import cf from "./mixins/cf";
	import mtm from "./mixins/mtm";
	import openPDF from "./mixins/openpdf";
	import customLog from "./mixins/CustomLog";
	import NonAutomatedData from "./mixins/NonAutomatedData";

	import Functions from "./mixins/Functions";
	import APICalls from "./mixins/APICalls";

	/* ======== OTHERS ======== */
	import config from "./config";


	export default {
		name: 'App',
		
		data() {
			return {
				idLead : config.idlead,
				lead: {},
				leadGDPR : {},
				isGDPRVisible : true,
				defaultLocale : "nl_BE",
				possibleLocales : [
					"fr_be", "nl_be", "fr_lu", "de_de", "en_be", 
					"en_uk", "fr_fr", "nl_nl", "fr_ch", "de_ch", 
					"de_lu", "it_it", "es_es", "pt_pt"],
			}
		},
		
		components: {
			LocaleSwitcher,
			ClassicFooter,
			PgFooter,
			ParticipationCounter,
			OneTrust,
			GdprBanner,
			SkipLink,
			CustomLoaders,
		},
		
		mixins: [
			ga,
			cf,
			mtm,
			customLog,
			openPDF,
			NonAutomatedData,
			Functions,
			APICalls,
			],

		computed: {
			getLang(){
				let lang = 'nl';
				if(this.$store.getters.getLang){
					lang = this.$store.getters.getLang;
				}
				return lang;
			},
		},


		methods: {
			closeGdpr(){
				this.isGDPRVisible = false;
			},

			getLocaleValidatedOrDefault(locale) {
				this.log("Regex testing", "low");
				const parts = locale.toLowerCase().split('_');
				const regex = /^[a-z]{2}$/;
				if (parts.length !== 2 || !regex.test(parts[0]) || !regex.test(parts[1])) {
					this.log(`This locale "${locale}" has a incorrect format`, "red");
					return this.defaultLocale;
				}else if(!this.possibleLocales.includes(locale.toLowerCase())) {
					this.log(`The locale "${locale}" is unknown or unauthorized`, "red");
					return this.defaultLocale;
				}else{
					this.log(`This locale "${locale}" has been validated`,"green");
					return parts[0] + '_' + parts[1].toUpperCase();
				}
			},

			getLangFromUrl () {
				this.log("Checking URL for locale or lang", "sep");

				this.$i18n.locale = this.defaultLocale;

				let urlParams = new URLSearchParams(window.location.search);
				let lang = '';
				let locale = '';

				if (urlParams.has('locale')){
					this.log("Getting locale from url", 'low');
					locale = urlParams.get('locale');
					this.$i18n.locale = this.getLocaleValidatedOrDefault(locale);
				}
				
				if (urlParams.has('lang')){
					this.log("Getting lang from url", 'low');
					lang = urlParams.get('lang');
					if (lang.toLowerCase() === "fr" || lang.toLowerCase() === "fr_be" || lang.toLowerCase() === "be_fr"){
						this.$i18n.locale = "fr_BE";
					}
				}
			},

			insertPixel() {
				/* INFO uncomment here to allow PIXEL insertion */
				// this.deployAndLog();
			},

			getGDPRData(idLead){
				this.log("Getting Leads for " + this.idLead, 'api');
				this.axios.get(
					`/leads/${idLead}`
					)
				.then(res => {
					this.log("I managed to get the lead for the GDPR", 'success');
					this.iGotTheGDPRLead(res);
					this.lead = res.data;
				})
				.catch(error => {
					this.log("I cannot get the lead for the GDPR", 'alert');
					this.iCannottGetTheGDPRLead(error);
				})
				.then(()=>{
				});
			},

			iGotTheGDPRLead(res){
				this.log(res.data);
				this.leadGDPR = {
					fabname : res.data.fabname,
					clientAddress : {
						street : res.data.gdprstreet,
						cityname: res.data.gdprloc,
						country: res.data.gdprcountry,
						postalcode: res.data.gdprcp,
						number: res.data.gdprstreetnum
					},
					clientContact: {
						email : res.data.gdprmail,
						name : res.data.gdprname,
						firstname : res.data.gdprfname
					}
				}

				// this.lead = res.data;
			},

			iCannottGetTheGDPRLead(error){
				console.log(error.response.data.errors);
				// this.displayModaleMessages(error.response.data.errors, 'alert');
				/* ? probably a redirection here is better : to show a adequat message. */
				/* TODO use the new errorsManager */
			},
		},
		

		beforeMount(){
			this.log("APP.VUE", "title");
			if(!this.$store.getters.getMaintenance){
				/* If not in maintenance mode */
				this.getLangFromUrl();
				this.getGDPRData(this.idLead);
			}else{
				console.log("Maintenance in progress");
			}
		},


		mounted(){
			// Added for vue devtool to work — don't remove
			window.postMessage({ devtoolsEnabled: true, vueDetected: true });
		},

	}

</script>


<style lang="scss">

</style>



